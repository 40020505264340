import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { SliceZone } from '../components'
import SEO from '../components/SEO'
import BreadCrumb from '../components/BreadCrumb'


const Course = ({ data: { courseData }, pageContext: { uid }, location }) => {
  const { data: course } = courseData

  return (
    <>
    <section className="hero hero-page">
      <div style={{background: "url(/img/courses-banner.jpg)"}} className="hero-banner"></div>
    </section>
    <section>
      <div className="container">
        <SEO pathname={location.pathname} />
          <BreadCrumb path={[{ label: "Kursy", to: `/kursy` }, { label: courseData.data.title, to: `kursy/${uid}` }]} />
          <div className="course-details-header">
            <h2 className="mb-4">{course.title}</h2>
            <div className="d-flex justify-content-between align-items-start mb-4 flex-column flex-lg-row">
              <div className="course-instructor">
                <div className="avatar"><img src={course.teacher_image.localFile && course.teacher_image.localFile.childImageSharp ? course.teacher_image.localFile.childImageSharp.fixed.src : ''} alt="Nauczyciel" className="img-fluid" /></div>
                <div className="name"><strong>{course.teacher}</strong></div>
              </div>
              <div className="course-information d-flex flex-wrap mt-4 mt-lg-0">
                <div className="cell border-left-0 border-top-0"><small>{course.category_title}</small><strong>{course.category}</strong></div>
                <div className="cell border-top-0"><small>{course.duration_title}</small><strong>{course.time}</strong></div>
                <div className="cell border-top-0 border-right-0"><small>{course.form_title}</small><strong>{course.form_value}</strong></div>
                <div className="cell border-bottom-0 border-left-0"><small>{course.number_title}</small><strong>{course.number_value}</strong></div>
                <div className="cell border-bottom-0"><small>{course.level_title}</small><strong>{course.level_value}</strong></div>
                <div className="cell border-bottom-0 border-right-0"><small>{course.enroll_title}</small><strong>{course.enroll_value}</strong></div>
              </div>
              <div className="course-price text-left text-lg-right"><small>Cena</small>
                <ul className="list-inline">
                  <li className="list-inline-item">
                    <div className="current">{course.price}<sup>zł</sup></div>
                    <div className="current" style={{fontSize: '1rem'}}>{course.price_description}</div>
                  </li>
                </ul>
                <div className="CTAs"><a href={`mailto:kontakt@oxfordon.pl?subject=Zainteresowanie kursem "${course.title}"`} className="btn btn-primary">Zapisz się</a>
                </div>
              </div>
            </div>
            <p><img src={course.course_image.localFile && course.course_image.localFile.childImageSharp ? course.course_image.localFile.childImageSharp.fluid.src : ''} alt={course.title} className="img-fluid" /></p>
          </div>
          <div className="course-details-body">
            <SliceZone allSlices={courseData.data.body} />
          </div>
        </div>
    </section>
      </>
  )
}

export default Course

export const pageQuery = graphql`
  query CourseItemPageQuery($uid: String!) {
    courseData: prismicCourse(uid: { eq: $uid }) {
      id
      data {
          title
          category
          teacher
          description
          price
          price_description
          category_title
          form_title
          form_value
          number_title
          number_value
          enroll_title
          enroll_value
          duration_title
          time
          level_title
          level_value
          teacher_image {
              localFile {
                  childImageSharp {
                      fixed(width: 150) {
                          src
                      }
                  }
              }
          }
          course_image {
              localFile {
                  childImageSharp {
                      fluid(maxWidth: 1200, quality: 90) {
                          tracedSVG
                          aspectRatio
                          src
                          srcSet
                          srcWebp
                          srcSetWebp
                          sizes
                          originalImg
                          originalName
                          presentationWidth
                          presentationHeight
                      }
                  }
              }
          }
          body {
                ... on PrismicCourseBodyTwoColumnText {
                    slice_type
                    id
                    primary {
                        left_column {
                            html
                        }
                        right_column {
                            html
                        }
                    }
                }
                  ... on PrismicCourseBodyText {
                      slice_type
                      id
                      primary {
                          text {
                              html
                              text
                          }
                      }
                  }
                  
                  ... on PrismicCourseBodyQuote {
                      slice_type
                      id
                      primary {
                          text {
                              text
                          }
                      }
                  }
              }
      }
    }
  }
`

Course.propTypes = {
  data: PropTypes.shape({
    pageData: PropTypes.object,
  }).isRequired,
  location: PropTypes.object.isRequired,
}
